import { Config } from '../../interfaces/config.interface';
import Icon from './icono.png';
import Logo from './logo.png';

const config: Config = {
  logo: Logo,
  icon: Icon,
  title: 'Voluntariado',
  location_zoom: 3,
  location_lng: -64.18877609999998,
  location_lat: -31.4200832,
  map_top: true,
  map_bottom: false,
  has_hours: true,
  activities_hours_table: true,
  name: 'Fonselp',
  translation: true,
  idiom: 'es-AR',
  withoutLogin: false,
  catalogTalents: true,
  download_contract: true,
  // has_country: true,
  community: true

};

export default config;
